/*

Slanted Font
font-family: eds-market-regular-slant,sans-serif;
font-weight: 400;
font-style: normal;

Light
font-family: franklin-gothic-urw, sans-serif;
font-weight: 300;
font-style: normal;

Book
font-family: franklin-gothic-urw, sans-serif;
font-weight: 400;
font-style: normal;

Medium
font-family: franklin-gothic-urw,sans-serif;
font-weight: 500;
font-style: normal;

Demi
font-family: franklin-gothic-urw, sans-serif;
font-weight: 700;
font-style: normal;

*/
$primary: #1700F4;
$secondary: #00095B;

@import "~@progress/kendo-theme-default/dist/all.scss";

a {
    color: #1700F4;
}

a:visited {
    color: #1700F4;
}

.shell {
    height: 100%;
}
