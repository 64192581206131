.content {
  width: auto;
  max-width: 1440px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #F5F5F5;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.2em;
  color: #00095B;
}

.ticket {
  background-color: #00095B;
  margin-top: -20px;
  padding-top: -60px;
  margin-right: 24px;
  width: 67%;
  padding-right: 20px;
}

.showtime {
  color: #1700F4;
  margin-top: -10px;
  font-size: min(max(60px, 5vw), 48px);
  font-family: eds-market-regular-slant,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.event-content-tickets {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.blue-table-tickets {
  border: 2px solid #00095B;
  color: #1700F4;
  margin-bottom: -2px;
  padding: 4px;
  font-size: 2.8ch;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}

.blue-table-tickets-arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 2px solid #00095B;
  color: #1700F4;
  margin-bottom: -2px;
  padding: 4px;
  font-size: 2.8ch;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}

.and-tickets {
  height: 1.15ch;
}

.do-share {
  color: #1700F4;
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: min(max(60px, 5vw), 40px);
  font-family: eds-market-regular-slant,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.social-buttons {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.social-button {
  padding: 10px;
  max-width: 44px;
}

@media (max-width:811px)  {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .ticket {
    width: 100%;
    padding-right: 0;
    margin-bottom: 40px;
  }

  .showtime {
    margin-top: -40px;
    font-size: 3em;
  }

  .event-content-tickets{
    width: 100%;
  }

  .blue-table-tickets, .blue-table-tickets-arrow {
    font-size: 2.2ch;
  }

  .do-share {
    margin-top: 5px;
    font-size: 1.9em;
  }
  }
