.logo {
  pointer-events: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 25px;
  width: 100%;
}

.wrapper {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: auto;
  max-width: 1440px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #F5F5F5;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.2em;
  color: #00095B;
}

.double-wide {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin-top: 10px;
  width: 100%;
}

.video {
  width: 67%;
  padding-right: 20px;
}

.video-inside{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video-player{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.event-content {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.blue-table {
  border: 3px solid #00095B;
  color: #1700F4;
  margin-bottom: -3px;
  padding: 4px;
  font-size: 2.8ch;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}

.dark-words {
  color: #00095B;
  font-size: 2.8ch;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

.colon {
  margin-left: 5px;
  margin-right: 5px;
}

.and-home {
  height: 1.15ch;
}

.lightning-theater {
  margin-top: 30px;
}

.no-wrap {
  white-space: nowrap;
}

.address {
  color: #1700F4;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6ch;
  margin-top: 25px;
}

.time {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #1700F4;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6ch;
  margin-bottom: 10px;
}

.arrow-wrapper {
  flex-grow:1;
  min-width: 44px;
  white-space: nowrap;
  margin-left: 3px;
  margin-right: 3px;
}

.arrow-right:after {
  content: "";
  display: inline-block !important;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: .65ch 0 .65ch .95ch;
  border-color: transparent transparent transparent #1700F4;
  vertical-align: middle;
}

.arrow-right:before {
  width: 80%;
  height: 2px;
  background: #1700F4;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.arrow-left:before {
  content: "";
  display: inline-block !important;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: .65ch 0 .65ch .95ch;
  border-color: transparent transparent transparent #1700F4;
  vertical-align: middle;
  transform: rotate(180deg);
}

.arrow-left:after {
  width: 80%;
  height: 2px;
  background: #1700F4;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.loader {
  width: 100%;
  text-align: center;
}

.get-tickets {
  background-color: #1700F4;
  border: 3px solid #1700F4;
  padding: 6px;
  margin: 10px 0 40px 0;
  color: #F5F5F5;
  font-size: min(max(16px, 1.8vw), 24px);
  font-family: eds-market-regular-slant,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.k-button-solid-base:hover {
  background-color: #00f;
}

@media (max-width:811px)  {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .double-wide {
    flex-direction: column;
  }

  .video {
    width: 100%;
    padding-right: 0;
    padding-bottom: 10px;
  }
  
  .event-content{
    width: 100%;
  }

  .blue-table {
    font-size: 2.5ch;
  }

  .lightning-theater {
    margin-top: 20px;
  }
  
  .address {
    font-size: 4.6vw;
    margin-top: 10px;
  }

  .time {
    font-size: 4.8vw;
  }

  .get-tickets {
    font-size: min(max(30px, 2vw), 24px);
  }
}
