.no-margin {
  margin: 0;
  padding: 0;
}

.form-wrapper {
  width: 50%;
}

.form-wrapper2 {
  width: 50%;
  margin-left: -3px;
}

.poster-wrapper {
  width: 67%;
  padding-right: 20px;
}

.lightning-theater-text {
  color: #00095B;
  margin-top: 8px;
  margin-bottom: -20px;
  font-size: 2.8ch;
  font-family: franklin-gothic-urw,sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

.registration {
  color: #1700F4;
  margin-top: -20px;
  margin-bottom: -30px;
  font-size: min(max(36px, 4vw), 60px);
  font-family: eds-market-regular-slant,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.form-input {
  background-color: #F5F5F5;
  margin: 0;
  padding: 0;
  color: #00095B;
  border-radius: 0;
  border: 3px solid #00095B;
  font-size: 2.5ch;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.form-checkbox {
  display: flex;
  flex-direction: row;
  color: #00095B;
  margin-top: 10px;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.legal {
  color: #00095B;
  margin-top: 10px;
  font-size: 1em;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.submit {
  width: 100%;
  margin-bottom: 40px;
  background-color: #1700F4;
  border: 3px solid #1700F4;
  border-radius: 0;
  color: #F5F5F5;
  font-size: 4ch;
  font-family: eds-market-regular-slant,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.k-floating-label-container > .k-label {
  color: #00095B;
  font-size: 2ch;
  font-family: franklin-gothic-urw,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.k-label {
  color: #00095B;
  font-size: 2ch;
  font-family: franklin-gothic-urw,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.k-checkbox {
  background-color: #F5F5F5;
  border-color: #00095B;
}

.k-checkbox:checked {
  background-color: #00095B;
  border-color: #00095B;
  color: #F5F5F5;
}

.k-checkbox:checked:focus, .k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px #1700F4;
}

@media (max-width:811px)  {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .form-wrapper {
    width: 100%;
  }

  .form-wrapper2 {
    margin: 0;
    width: 100%;
  }
  
  .poster-wrapper {
    width: 100%;
    padding-right: 0;
    padding-bottom: 10px;
  }

  .lightning-theater-text {
    font-size: 2.3ch;
    margin-bottom: -4px;
  }
  
  .registration {
    font-size: min(max(46px, 4ch), 50px);
    margin-top: -10px;
    margin-bottom: -20px;
  }
  
  .address-rsvp {
    font-size: 5vw;
  }


  .submit {
    margin-bottom: 20px;
  }
}
